import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  PaginatedList,
  List,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import { getCompanies } from "../../../services/Companies";
import { ValidPath } from "../../../RouterSwitch";
import { useAvailablePath } from "../../../hooks";
import { CompaniesConfigData, CompaniesListData } from "./type";
import { store } from "../../../store";
import { FileExcelTwoTone } from "@ant-design/icons";

const CompaniesList = (): JSX.Element => {
  const history = useHistory();
  const [availablePathDictionary] = useAvailablePath();
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState<CompaniesListData>([]);
  const [configData, setConfigData] = useState<CompaniesConfigData>();

  const { config } = store.getState();
  const baseUrl = config.apiHost;

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async () => {
      setLoading(true);
      const { data } = await getCompanies();
      
      if (!isMounted) {
        return;
      }

      if (data) {
        setListData(data.list.sort((a, b) => (a.name > b.name ? 1 : -1)));
        setConfigData(data.config);
      }
      setLoading(false);
    };
    handleFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  const customRenderItem = ({
    name,
    id,
    officeName,
    officeId,
  }: Record<string, string | boolean | undefined>) => (
    <List.Item
      className="list-item"
      actions={[
        <a
          href={`${baseUrl}/${ValidPath.companies}/${id}/travellers/download`}
          target="_blank"
          className="no-text-underline">
          <span>
            <FileExcelTwoTone />
            exportar viajeros
          </span>
        </a>,
        <Link
          to={`/${ValidPath.companies}/update/${id}`}
          className="no-text-underline">
          <span>
            <Icons icon={validateIconType("edit")} />
            {configData?.list?.button}
          </span>
        </Link>
      ]}
    >
      <List.Item.Meta
        title={
          <Link
            to={`/${ValidPath.companies}/update/${id}`}
            className="no-text-underline">
            {name}
          </Link>
        }
        description={
          <Link
            to={`/${ValidPath.offices}/update/${officeId}`}
            className={
              availablePathDictionary[ValidPath.offices]
                ? "no-text-underline"
                : "disabled-link info-color no-text-underline"
            }
          >
            {officeName}
          </Link>
        }
      />
    </List.Item>
  );

  return (
    <PaginatedList
      listData={listData}
      configData={configData}
      loading={loading}
      customRenderItem={customRenderItem}
      handleNavigationClick={(to) => {
        history.push(`/${ValidPath.companies}${to}`);
      }}
    />
  );
};

export default CompaniesList;
