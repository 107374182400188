/* eslint-disable max-lines */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./UsersForm.scss";
import {
  PageHeader,
  FormGenerator,
  SpinnerInContainer,
  Icons,
  validateIconType,
} from "@triporate/triporate-design-system";
import {
  FormData,
  InputData,
} from "@triporate/triporate-design-system/dist/components/form/FormGenerator/types";
import {
  getUserById,
  getUsersFormConfig,
  upsertUser,
} from "../../../services/Users";
import { inputValidation } from "../../../services/InputValidation";
import { uploadAsset } from "../../../services/Assets";
import { getSelectOptions } from "../../../services/SelectOptions";
import { ValidPath } from "../../../RouterSwitch";
import { panelNotification } from "../../../utils/panelNotification";
import { useFormMode } from "../../../hooks";
import GoBackHeaderButton from "../GoBackHeaderButton";
import RoleSelect, { ValidRoles } from "./RoleSelect";
import { UserFormInputsConfigData } from "./types";
import { Warnings } from "../../../services/formConfigTypes";
import ModalWelcome from "../../ModalWelcome";
import { SubmitResultHandler } from "../../../hooks/useTravellerForm/types";
import { ModalData } from "../../ModalWelcome/types";

const UsersForm: FunctionComponent = ({ ...props }) => {
  const [userId, mode] = useFormMode("userId");
  const history = useHistory();
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formInputsConfig, setFormInputsConfig] =
    useState<UserFormInputsConfigData | null>(null);
  const [roleInputs, setRoleInputs] = useState<FormData["inputs"]>([]);
  const [initialValues, setInitialValues] = useState<Record<string, unknown>>({
    mode,
  });

  const [sectionTitles, setSectionTitles] = useState({
    create: "",
    update: "",
  });
  const [btnText, setBtnText] = useState("");
  const [saveBtnText, setSaveBtnText] = useState("");
  const [role, setRole] = useState<ValidRoles | undefined>();
  const [warns, setWarns] = useState<Warnings>();
  const [warnByRole, setWarnByRole] = useState<string>();
  const [modalData, setModalData] = useState<ModalData>();

  const validateRole = (strRole?: string): ValidRoles | undefined => {
    if (strRole && strRole in ValidRoles) return strRole as ValidRoles;
    return;
  };

  const onSuccess: SubmitResultHandler = (data) => {
    panelNotification("success", data);
    history.push(`/${ValidPath.users}`);
  };

  const onError: SubmitResultHandler = (data) => {
    panelNotification("error", data);
  };

  useEffect(() => {
    let isMounted = true;
    const handleOfficesFormConfigFetch = async () => {
      setLoadingConfig(true);
      const { data } = await getUsersFormConfig();
      if (!isMounted) return;
      if (data) {
        setFormInputsConfig(data.inputs);
        setWarns(data.warnings);
        setSectionTitles((prevState) => ({
          ...prevState,
          ...data.config.title,
        }));
        setBtnText((prevState) => data.config.headerButton || prevState);
        setSaveBtnText(data.config.saveButton);
      }
      setLoadingConfig(false);
    };
    handleOfficesFormConfigFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async (userId: string) => {
      setLoadingInitialValues(true);
      const { data } = await getUserById(userId);

      if (!isMounted) return;

      if (data) {
        const validInitialValues = data as unknown as Record<string, unknown>;
        setInitialValues((prevState) => ({
          ...prevState,
          ...validInitialValues,
        }));
        setRole(validateRole(data.role));
      }
      setLoadingInitialValues(false);
    };
    if (userId) {
      handleFetch(userId);
    } else {
      setLoadingInitialValues(false);
    }

    return () => {
      isMounted = false;
    };
  }, [userId]);

  const handleFormSubmit = async (values: Record<string, unknown>) => {
    setLoadingSubmit(true);

    const body = JSON.stringify(values);
    const { error, data } = await upsertUser(body, userId);

    if (data.modal) {
      setModalData({ modal: data.modal, response: { error, data } });
      return;
    }

    if (!error) {
      onSuccess(data);
    } else {
      setLoadingSubmit(false);
      onError(data);
    }
  };

  useEffect(() => {
    if (role && formInputsConfig) {
      const roleInputsConfig = formInputsConfig[role] || [];

      warns && role in warns ? setWarnByRole(warns[role]) : setWarnByRole("");

      setRoleInputs([...roleInputsConfig]);
    } else setRoleInputs([]);
  }, [role, formInputsConfig, initialValues, warns]);

  if (loadingConfig || loadingInitialValues || !formInputsConfig)
    return <SpinnerInContainer />;

  const formatedData = formInputsConfig.role?.[0] as InputData;
  const inputValidationWithUserId = (url: string, value: unknown) => {
    const valueWithId = { value, id: userId };
    return inputValidation(url, valueWithId);
  };

  return (
    <>
      <PageHeader
        title={sectionTitles[mode]}
        extra={[
          <GoBackHeaderButton key="go-back-header-button" text={btnText} />,
        ]}
      />
      <FormGenerator
        submitButtonLabel={saveBtnText}
        formData={[
          {
            section: formInputsConfig.section,
            inputs: roleInputs,
          },
        ]}
        isUpdate={!!userId}
        initialValues={initialValues}
        inputValidator={inputValidationWithUserId}
        onFinish={handleFormSubmit}
        loading={loadingSubmit}
        disabled={!role}
        getSelectOptions={getSelectOptions}
        uploadAsset={uploadAsset}
      >
        <RoleSelect
          {...props}
          value={role}
          onChange={(value) => setRole(validateRole(value))}
          inputData={formatedData}
        />
        {!!warnByRole?.length && (
          <div className="warningAlert">
            <Icons icon={validateIconType(warns?.icon)}></Icons>
            <p
              style={{ marginLeft: "15px", marginBottom: 0 }}
              dangerouslySetInnerHTML={{
                __html: warnByRole || "",
              }}
            ></p>
          </div>
        )}
      </FormGenerator>
      {modalData && (
        <ModalWelcome
          modalData={modalData}
          onSuccess={onSuccess}
          onError={onError}
        />
      )}
    </>
  );
};

export default UsersForm;
