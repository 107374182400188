import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  List,
  PaginatedList,
  Icons,
  validateIconType,
  Switch,
} from "@triporate/triporate-design-system";
import { getTravellers } from "../../../services/Travellers";
import { ValidPath } from "../../../RouterSwitch";
import { TravellersConfigData, TravellersListData } from "./type";
import EnableTravellerModal from "./EnableTravellerModal";
import "./List.scss";

const TravellersList = (): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState<TravellersListData>([]);
  const [travellersConfigData, setTravellersConfigData] =
    useState<TravellersConfigData>();
  const [travellerModalData, setTravellerModalData] = useState<{
    id: string;
    enable: boolean;
    name: string;
  }>();

  useEffect(() => {
    let isMounted = true;
    const handleFetch = async () => {
      setLoading(true);
      const { data } = await getTravellers();

      if (!isMounted) {
        return;
      }

      if (data) {
        if (data.list?.length > 0)
          setListData(
            data.list.sort((a, b) => {
              if (a.company === b.company) {
                if (a.name === b.name) return 0;
                return a.name < b.name ? -1 : 1;
              } else {
                return a.company < b.company ? -1 : 1;
              }
            })
          );
        setTravellersConfigData(data.config);
      }
      setLoading(false);
    };
    handleFetch();

    return () => {
      isMounted = false;
    };
  }, []);

  const customRenderItem = ({
    name,
    id,
    company,
    enable,
  }: Record<string, string | boolean | undefined>) => (
    <List.Item
      className="travellers-list-item list-item"
      actions={[
        <Link
          to={`/${ValidPath.travellers}/update/${id}`}
          key="list-loadmore-edit"
        >
          <span>
            <Icons icon={validateIconType("edit")} />
            {travellersConfigData?.list.button}
          </span>
        </Link>,
        <Switch
          key="list-activate"
          checked={!!enable}
          onChange={() => {
            setTravellerModalData({
              id: `${id}`,
              name: `${name}`,
              enable: !!enable,
            });
          }}
        />,
      ]}
    >
      <Link
        to={`/${ValidPath.travellers}/update/${id}`}
        key="list-loadmore-edit"
        className="no-text-underline travellers-list-link"
      >
        <List.Item.Meta title={name} description={company} />
      </Link>
    </List.Item>
  );

  return (
    <>
      <PaginatedList
        listData={listData}
        className="adminPanel-list"
        configData={travellersConfigData}
        loading={loading}
        customRenderItem={customRenderItem}
        handleNavigationClick={(to) => {
          history.push(`/${ValidPath.travellers}${to}`);
        }}
      />
      <EnableTravellerModal
        travellersData={travellerModalData}
        config={travellersConfigData?.enableModal}
        cleanTravellersData={() => setTravellerModalData(undefined)}
        updateTravellerListData={(id, enable) => {
          setListData((prevState) =>
            prevState.map((traveller) => {
              if (traveller.id === id) {
                return { ...traveller, enable };
              }
              return traveller;
            })
          );
        }}
      />
    </>
  );
};

export default TravellersList;
