import AuthToken from "./AuthToken";
export default class FetchService extends AuthToken {
  public constructor() {
    super();
  }

  public async get(
    url: string
  ): Promise<ReturnType<FetchService["handleFetch"]>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (!url) return { error: true };
    return this.handleFetch(url, config);
  }

  public async post(
    url: string,
    body: BodyInit | null | undefined
  ): Promise<ReturnType<FetchService["handleFetch"]>> {
    const config: RequestInit = {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (typeof body !== "string") delete config.headers;
    if (!url || !body) return { error: true };

    return this.handleFetch(url, config);
  }

  public async put(
    url: string,
    body: BodyInit | null | undefined
  ): Promise<ReturnType<FetchService["handleFetch"]>> {
    const config: RequestInit = {
      method: "PUT",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (typeof body !== "string") delete config.headers;
    if (!url || !body) return { error: true };

    return this.handleFetch(url, config);
  }

  public async delete(
    url: string
  ): Promise<ReturnType<FetchService["handleFetch"]>> {
    const config = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (!url) return { error: true };
    return this.handleFetch(url, config);
  }

  public async postAsset(
    url: string,
    file: any
  ): Promise<ReturnType<FetchService["handleFetch"]>> {
    if (!url || !file) return { error: true };

    const formData = new FormData();
    formData.append("picture", file);

    const config: RequestInit = {
      method: "POST",
      body: formData,
    };

    return this.handleFetch(url, config);
  }

  private async handleFetch(
    url: string,
    config: RequestInit
  ): Promise<{
    error?: boolean;
    data?: unknown;
  }> {
    const token = this.getToken();
    const init = {
      ...config,
      headers: {
        ...(config.headers || {}),
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetch(url, init);

    const error = !response.ok;

    if (response.status === 401) this.handleLogout();

    const data: unknown = await response.json();
    if (error) return { error, data };
    return { data };
  }

  protected handleLogout = async (): Promise<void> => {
    const token = this.getToken();
    const { role } = this.parseJwt(token);
    const response = await this.get(`${this.baseUrl}/auth/logout?role=${role}`);
    window.location.href = response.data as string;
  };
  private parseJwt(token: string | null) {
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    } else {
      throw new Error("No token");
    }
  }
}
