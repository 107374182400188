import { FetchService } from "../FetchService";
import mockData from "./mockTravellers.json";
import { travellerURI } from "./uri";

interface TravellersData {
  config: TravellersConfig;
  list: Traveller[];
}

interface TravellersConfig {
  list: ConfigItem;
  pageHeader: ConfigItem;
  enableModal: EnableModal;
}

interface EnableModal {
  patterns: Record<string, string>;
  actions: Record<string, string>;
  message: string;
  okText: string;
  cancelText: string;
}

interface ConfigItem {
  title: string;
  button: string;
  searchPlaceholder?: string;
}

interface Traveller {
  name: string;
  id: string;
  company: string;
  enable: boolean;
}

interface Response {
  error?: boolean;
  data?: TravellersData;
}

const handleMockData = (): Response => {
  return { data: mockData, error: false };
};

export async function getTravellers(): Promise<Response> {
  const fetchService = new FetchService();
  if (fetchService.useFakeApi) return handleMockData();

  const url = fetchService.baseUrl + travellerURI + "/list";
  const { data, error } = await fetchService.get(url);

  if (!error) {
    const castedData = data as Response["data"];
    return { data: castedData };
  }

  return { error };
}
